/**
 * This is the main application stylesheet. It should include or import all
 * stylesheets used throughout the application as this is the only stylesheet in
 * the Grunt configuration that is automatically processed.
 */
/**
 * These are the variables used throughout the application. This is where 
 * overwrites that are not specific to components should be maintained.
 */
/**
 * Typography-related.
 */
.INVALID_INPUT_ELEM {
  color: #a54243;
  border-color: #a54243;
}
.INVALID_INPUT_ELEM:focus {
  border-color: #a54243;
  box-shadow: none;
  outline: 0 none;
}
.FORM_ERROR_TEXT {
  margin: 0 auto 20px;
  position: relative;
}
.FORM_ERROR_TEXT .error {
  left: 0;
  position: absolute;
  font-size: 12px;
  color: #a54243;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Aligner-item {
  max-width: 50%;
}
.Aligner-item--top {
  align-self: flex-start;
}
.Aligner-item--bottom {
  align-self: flex-end;
}
.btn {
  font-size: 14px;
  position: relative;
  outline: none!important;
  box-shadow: none!important;
}
.btn h1,
.btn h2,
.btn h3,
.btn h4,
.btn h5,
.btn h6 {
  margin: 0px;
}
.btn:hover,
.btn:focus,
.btn:focus.active,
.btn.active,
.btn:active {
  border-color: #428bca !important;
  color: #428bca !important;
  background-color: #fff !important;
}
.btn:hover.btn-danger,
.btn:focus.btn-danger,
.btn:focus.active.btn-danger,
.btn.active.btn-danger,
.btn:active.btn-danger {
  border-color: #a54243 !important;
  color: #a54243 !important;
}
.btn.btn-primary {
  background-color: #428bca !important;
  color: #FFFFFF !important;
  border: 1px solid #428bca;
}
.btn.btn-primary.active,
.btn.btn-primary:hover {
  color: #428bca !important;
  background-color: #FFFFFF !important;
  border: 1px solid #428bca;
}
.btn:last-child {
  margin-right: 0px;
}
.btn-default {
  color: #212121;
  background-color: #FFFFFF;
  border-color: #212121;
}
.btn-default:focus,
.btn-default.focus {
  color: #212121;
  background-color: #e6e6e6;
  border-color: #000000;
}
.btn-default:hover {
  color: #212121;
  background-color: #e6e6e6;
  border-color: #020202;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #212121;
  background-color: #e6e6e6;
  border-color: #020202;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #212121;
  background-color: #d4d4d4;
  border-color: #000000;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #FFFFFF;
  border-color: #212121;
}
.btn-default .badge {
  color: #FFFFFF;
  background-color: #212121;
}
.btn-primary {
  color: #428bca;
  background-color: #FFFFFF;
  border-color: #428bca;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #428bca;
  background-color: #e6e6e6;
  border-color: #1f496e;
}
.btn-primary:hover {
  color: #428bca;
  background-color: #e6e6e6;
  border-color: #2d6ca2;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #428bca;
  background-color: #e6e6e6;
  border-color: #2d6ca2;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #428bca;
  background-color: #d4d4d4;
  border-color: #1f496e;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #FFFFFF;
  border-color: #428bca;
}
.btn-primary .badge {
  color: #FFFFFF;
  background-color: #428bca;
}
.btn-danger {
  color: #428bca;
  background-color: #FFFFFF;
  border-color: #428bca;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #428bca;
  background-color: #e6e6e6;
  border-color: #1f496e;
}
.btn-danger:hover {
  color: #428bca;
  background-color: #e6e6e6;
  border-color: #2d6ca2;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #428bca;
  background-color: #e6e6e6;
  border-color: #2d6ca2;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #428bca;
  background-color: #d4d4d4;
  border-color: #1f496e;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #FFFFFF;
  border-color: #428bca;
}
.btn-danger .badge {
  color: #FFFFFF;
  background-color: #428bca;
}
.theme-site .md-padding {
  padding: 20px;
}
.theme-site .md-fake-disabled-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  border: 0;
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #d4d4d4;
}
.theme-site .md-button.checkbox-align {
  /* padding-left: 0px; */
  margin-left: -7px;
}
.theme-site .material-icons.small {
  font-size: 14px;
}
.theme-site .md-button.md-fab.md-mini:not([disabled]) {
  transition: 0.3s;
}
.theme-site .md-button.md-fab.md-mini:not([disabled]).active {
  background-color: #428bca;
}
.theme-site .md-button.md-fab.md-mini:not([disabled]).md-primary {
  background-color: #428bca;
  color: #FFFFFF;
}
.theme-site .md-button.md-fab.md-mini:not([disabled]).md-primary.active {
  background-color: #36465d;
}
.theme-site .md-button.md-primary:not([disabled]):hover {
  background-color: #36465d;
  color: #FFFFFF;
  transition: 0.3s;
}
.theme-site .md-button.md-default-theme.md-accent,
.theme-site .md-button.md-accent {
  background-color: transparent;
  color: #a54243;
}
.theme-site .md-button.md-default-theme,
.theme-site .md-button {
  background-color: transparent;
  color: #428bca;
}
.theme-site a.md-button.md-default-theme.md-accent,
.theme-site a.md-button.md-accent {
  background-color: transparent;
  color: #a54243;
}
.theme-site .md-button {
  font-weight: 300;
}
.theme-site .md-button.md-primary {
  color: #FFFFFF;
  background-color: #428bca;
}
.theme-site .md-button:disabled {
  color: #d4d4d4 !important;
}
.theme-site .disabled {
  color: #d4d4d4;
}
.theme-site a.md-button,
.theme-site .link.md-button {
  background-color: transparent;
  color: #212121;
  text-transform: none;
}
.theme-site a.md-button:hover,
.theme-site .link.md-button:hover {
  color: #428bca;
  background-color: transparent;
}
.theme-site a.md-button.md-primary,
.theme-site .link.md-button.md-primary {
  color: #428bca;
}
.theme-site a.md-button.md-accent,
.theme-site .link.md-button.md-accent {
  background-color: transparent;
  color: #a54243;
}
.theme-site a.md-button.site-button--colored,
.theme-site .link.md-button.site-button--colored {
  background-color: #428bca;
  color: #FFFFFF;
}
.theme-site a.md-button.site-button--colored:hover,
.theme-site .link.md-button.site-button--colored:hover {
  background-color: #36465d;
}
.theme-site a.md-button.site-button--colored.active,
.theme-site .link.md-button.site-button--colored.active {
  background-color: #36465d;
}
.theme-site h2.link {
  cursor: pointer;
  cursor: hand;
}
.theme-site .md-button {
  min-width: 36px;
}
.theme-site .md-button.md-default-theme.md-primary,
.theme-site .md-button.md-default-theme.md-primary-raised {
  background-color: #428bca;
  color: #FFFFFF;
}
.theme-site .md-button.md-default-theme.md-primary:not([disabled]):hover,
.theme-site .md-button.md-default-theme.md-primary-raised:not([disabled]):hover {
  background-color: #36465d;
  transition: 0.3s;
}
.theme-site md-tabs.md-default-theme .md-tab.md-active,
.theme-site md-tabs .md-tab.md-active,
.theme-site md-tabs.md-default-theme .md-tab.md-active md-icon,
.theme-site md-tabs .md-tab.md-active md-icon,
.theme-site md-tabs.md-default-theme .md-tab.md-focused,
.theme-site md-tabs .md-tab.md-focused,
.theme-site md-tabs.md-default-theme .md-tab.md-focused md-icon,
.theme-site md-tabs .md-tab.md-focused md-icon {
  color: #428bca;
}
.theme-site md-tabs.md-default-theme md-ink-bar,
.theme-site md-tabs md-ink-bar {
  background: #428bca none repeat scroll 0 0;
  color: #428bca;
}
.theme-site md-content.md-default-theme,
.theme-site md-content {
  background-color: #FFFFFF;
}
.theme-site md-input-container.md-default-theme.md-input-focused:not(.md-input-invalid) .md-input,
.theme-site md-input-container.md-input-focused:not(.md-input-invalid) .md-input,
.theme-site md-input-container.md-default-theme.md-input-resized:not(.md-input-invalid) .md-input,
.theme-site md-input-container.md-input-resized:not(.md-input-invalid) .md-input {
  border-color: #428bca;
}
.theme-site md-input-container.md-default-theme.md-input-focused:not(.md-input-invalid) label,
.theme-site md-input-container.md-input-focused:not(.md-input-invalid) label,
.theme-site md-input-container.md-default-theme.md-input-focused:not(.md-input-invalid) md-icon,
.theme-site md-input-container.md-input-focused:not(.md-input-invalid) md-icon {
  color: #428bca;
}
.theme-site md-checkbox.md-default-theme.md-checked .md-icon,
.theme-site md-checkbox.md-checked .md-icon {
  background-color: #428bca;
  color: #FFFFFF;
}
.theme-site md-input-container.md-default-theme.md-input-has-value:not(.md-input-invalid) label,
.theme-site md-input-container.md-input-has-value:not(.md-input-invalid) label {
  color: #428bca;
}
.theme-site .md-text p {
  margin: 0;
}
.theme-site .main-card {
  min-width: 300px;
}
.theme-site md-input-container .md-input-invalid .ng-touched.ng-invalid {
  color: #a54243;
}
.theme-site md-input-container.md-default-theme.md-input-invalid label,
.theme-site md-input-container.md-input-invalid label,
.theme-site md-input-container.md-default-theme.md-input-invalid .md-input-message-animation,
.theme-site md-input-container.md-input-invalid .md-input-message-animation,
.theme-site md-input-container.md-default-theme.md-input-invalid .md-char-counter,
.theme-site md-input-container.md-input-invalid .md-char-counter {
  color: #a54243;
}
.theme-site md-input-container.md-default-theme.md-input-invalid .md-input,
.theme-site md-input-container.md-input-invalid .md-input {
  border-color: #a54243;
}
.theme-site md-input-container.md-input-has-value input {
  color: #428bca;
  border-color: #428bca;
}
.theme-site md-input-container.md-input-has-value.md-input-invalid input {
  color: #a54243;
  border-color: #a54243;
}
.theme-site md-input-container.md-input-has-value.md-input-invalid label {
  color: #a54243;
}
.theme-site md-checkbox .md-label > * {
  color: #212121;
}
.theme-site md-tooltip {
  background-color: rgba(0, 0, 0, 0.9);
  color: #FFFFFF;
  padding: 10px;
  font-size: 1rem;
  text-align: left;
}
.theme-site md-tooltip .md-content {
  height: auto;
}
.theme-site md-input-container.md-input-focused label:not(.md-no-float),
.theme-site md-input-container.md-input-has-placeholder label:not(.md-no-float),
.theme-site md-input-container.md-input-has-value label:not(.md-no-float) {
  font-weight: 700;
}
.theme-site .ng-messages-fix {
  visibility: hidden!important;
}
.theme-site md-progress-circular {
  z-index: 1;
}
.theme-site md-progress-circular path {
  stroke: #428bca;
}
.theme-site md-progress-circular.md-accent path {
  stroke: #a54243;
}
.theme-site md-card {
  background-color: #FFFFFF;
}
.theme-site md-input-container .md-input {
  border-color: #212121;
}
.theme-site .md-dialog-container {
  z-index: 99999;
}
.mdl-textfield__error {
  color: #a54243;
}
.mdl-textfield__error.ng-active {
  visibility: visible;
}
.ng-invalid .mdl-textfield--floating-label.is-focused,
.is-invalid .mdl-textfield--floating-label.is-focused,
.ng-invalid .mdl-textfield--floating-label.is-dirty,
.is-invalid .mdl-textfield--floating-label.is-dirty,
.ng-invalid .mdl-textfield--floating-label,
.is-invalid .mdl-textfield--floating-label {
  color: #a54243;
}
.ng-invalid .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.is-invalid .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.ng-invalid .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.is-invalid .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.ng-invalid .mdl-textfield--floating-label .mdl-textfield__label,
.is-invalid .mdl-textfield--floating-label .mdl-textfield__label {
  color: #a54243;
}
.ng-invalid .mdl-textfield--floating-label.is-focused .mdl-textfield__label::after,
.is-invalid .mdl-textfield--floating-label.is-focused .mdl-textfield__label::after,
.ng-invalid .mdl-textfield--floating-label.is-dirty .mdl-textfield__label::after,
.is-invalid .mdl-textfield--floating-label.is-dirty .mdl-textfield__label::after,
.ng-invalid .mdl-textfield--floating-label .mdl-textfield__label::after,
.is-invalid .mdl-textfield--floating-label .mdl-textfield__label::after {
  background-color: #a54243;
}
.mdl-textfield__input.ng-invalid,
.mdl-textfield__input.is-invalid {
  border-color: #a54243;
}
.ng-valid .mdl-textfield__input,
.ng-pristine .mdl-textfield__input,
.ng-valid + .mdl-textfield__input,
.ng-pristine + .mdl-textfield__input {
  color: #428bca;
}
.ng-valid .mdl-textfield__input.is-untouched,
.ng-pristine .mdl-textfield__input.is-untouched,
.ng-valid + .mdl-textfield__input.is-untouched,
.ng-pristine + .mdl-textfield__input.is-untouched,
.ng-valid .mdl-textfield__input.ng-pristine,
.ng-pristine .mdl-textfield__input.ng-pristine,
.ng-valid + .mdl-textfield__input.ng-pristine,
.ng-pristine + .mdl-textfield__input.ng-pristine {
  border-color: #428bca;
}
.ng-valid .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.ng-pristine .mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.ng-valid .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.ng-pristine .mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.ng-valid .mdl-textfield--floating-label .mdl-textfield__label,
.ng-pristine .mdl-textfield--floating-label .mdl-textfield__label {
  color: #428bca;
}
.ng-valid .mdl-textfield--floating-label.is-focused .mdl-textfield__label:after,
.ng-pristine .mdl-textfield--floating-label.is-focused .mdl-textfield__label:after,
.ng-valid .mdl-textfield--floating-label.is-dirty .mdl-textfield__label:after,
.ng-pristine .mdl-textfield--floating-label.is-dirty .mdl-textfield__label:after,
.ng-valid .mdl-textfield--floating-label .mdl-textfield__label:after,
.ng-pristine .mdl-textfield--floating-label .mdl-textfield__label:after {
  background-color: #428bca;
}
.ng-valid .mdl-textfield--floating-label.is-focused .mdl-textfield__error,
.ng-pristine .mdl-textfield--floating-label.is-focused .mdl-textfield__error,
.ng-valid .mdl-textfield--floating-label.is-dirty .mdl-textfield__error,
.ng-pristine .mdl-textfield--floating-label.is-dirty .mdl-textfield__error,
.ng-valid .mdl-textfield--floating-label .mdl-textfield__error,
.ng-pristine .mdl-textfield--floating-label .mdl-textfield__error {
  color: #212121;
}
.ng-valid.mdl-textfield__input + .mdl-textfield__label::after,
.ng-valid.mdl-textfield__input + .mdl-textfield__label::after {
  background-color: #428bca !important;
}
.ng-valid.mdl-textfield__input.is-focused,
.ng-valid.mdl-textfield__input.is-focused,
.ng-valid.mdl-textfield__input + .mdl-textfield__label.is-focused,
.ng-valid.mdl-textfield__input + .mdl-textfield__label.is-focused,
.ng-valid.mdl-textfield__input.is-dirty,
.ng-valid.mdl-textfield__input.is-dirty,
.ng-valid.mdl-textfield__input + .mdl-textfield__label.is-dirty,
.ng-valid.mdl-textfield__input + .mdl-textfield__label.is-dirty,
.ng-valid.mdl-textfield__input,
.ng-valid.mdl-textfield__input,
.ng-valid.mdl-textfield__input + .mdl-textfield__label,
.ng-valid.mdl-textfield__input + .mdl-textfield__label {
  color: #428bca !important;
}
.ng-valid.mdl-textfield__input + .mdl-textfield__error,
.ng-valid.mdl-textfield__input + .mdl-textfield__error,
.ng-valid.mdl-textfield__input + .mdl-textfield__label + .mdl-textfield__error,
.ng-valid.mdl-textfield__input + .mdl-textfield__label + .mdl-textfield__error {
  color: #212121;
}
.mdl-button[disabled]:hover,
.mdl-button.mdl-button--disabled:hover {
  background-color: transparent;
}
.theme-site md-card md-card-header md-card-header-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme-site md-card md-card-content {
  padding: 0;
  margin: 16px;
}
.mdl-spinner__layer-1,
.mdl-spinner__layer-2 {
  border-color: #428bca;
}
.mdl-spinner__layer-3,
.mdl-spinner__layer-4 {
  border-color: #a54243;
}
.mdl-tabs__panel {
  max-height: 500px;
  overflow: scroll;
  transform: translate3d(0px, 0px, 0px);
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1) 0s, -webkit-transform 0.5s cubic-bezier(0.35, 0, 0.25, 1) 0s;
}
.theme-site .md-subheader,
.theme-site .md-title {
  font-weight: 500;
}
.theme-site md-dialog {
  background-color: #FFFFFF;
}
.theme-site md-backdrop.md-opaque {
  background-color: #000;
}
.theme-site md-toolbar.md-primary {
  background-color: #428bca;
}
.theme-site md-toolbar.md-primary .md-toolbar-tools * {
  color: #FFFFFF;
  background-color: transparent;
}
.theme-site md-dialog .md-toolbar-tools {
  border-bottom: 1px solid #d4d4d4;
}
.theme-site md-dialog .md-actions,
.theme-site md-dialog md-dialog-actions {
  border-top: 1px solid #d4d4d4;
}
.theme-site .md-open-menu-container md-menu-divider {
  background: #d4d4d4;
}
.theme-site md-tooltip {
  height: auto!important;
}
.theme-site md-backdrop.md-dialog-backdrop {
  z-index: 101;
}
.theme-site md-dialog > *:first-child {
  box-direction: normal;
  box-orient: vertical;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.theme-site md-fab-speed-dial {
  outline: none;
}
.theme-site md-dialog .md-actions .md-button,
.theme-site md-dialog md-dialog-actions .md-button {
  margin: 8px;
}
.theme-site md-dialog .md-actions,
.theme-site md-dialog md-dialog-actions {
  padding-left: 16px;
  padding-right: 16px;
}
.theme-site .hint {
  bottom: 7px;
  color: #333;
  font-size: 12px;
  left: 2px;
  line-height: 14px;
  position: absolute;
  right: auto;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
}
.theme-site .hint.ng-hide,
.theme-site .hint.ng-enter,
.theme-site .hint.ng-leave.ng-leave-active {
  bottom: 26px;
  opacity: 0;
}
.theme-site .hint.ng-leave,
.theme-site .hint.ng-enter.ng-enter-active {
  bottom: 7px;
  opacity: 1;
}
.theme-site .hint-2line br {
  display: none;
}
.theme-site .hidden {
  display: none;
}
.theme-site .center {
  align-self: center;
}
.theme-site .md-padding-left {
  padding-left: 20px;
}
.theme-site .md-virtual-repeat-container.md-autocomplete-suggestions-container,
.theme-site .autocomplete-custom-template {
  background: #FFFFFF;
}
.theme-site .autocomplete-custom-template li {
  border-bottom: 1px solid #d4d4d4;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal;
}
.theme-site .autocomplete-custom-template li:last-child {
  border-bottom-width: 0;
}
.theme-site .autocomplete-custom-template .item-title,
.theme-site .autocomplete-custom-template .item-metadata {
  display: block;
  line-height: 2;
}
.theme-site .autocomplete-custom-template .item-title md-icon {
  height: 18px;
  width: 18px;
}
.theme-site md-option * {
  max-width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.theme-site md-option,
.theme-site .autocomplete-custom-template {
  background: #FFFFFF;
  height: auto;
  min-height: 48px;
  padding: 10px;
  line-height: 2;
}
.theme-site md-option .detail,
.theme-site .autocomplete-custom-template .detail {
  padding-left: 8px;
}
.theme-site md-option + md-option {
  border-top: 1px solid #d4d4d4;
}
.theme-site .italic {
  font-style: italic;
}
.theme-site .md-select-menu-container {
  max-width: 600px;
}
.theme-site .middle-card-content {
  margin: 0px!important;
  padding: 16px 36px 16px 36px;
  border-bottom: 1px solid #d4d4d4;
  border-top: 1px solid #d4d4d4;
}
@media all and (max-width: 600px) {
  .theme-site .md-select-menu-container,
  .theme-site md-select-menu {
    max-width: 98%;
  }
}
@media all and (max-width: 480px) {
  .theme-site .hint-2line {
    bottom: -7px;
  }
  .theme-site .hint-2line br {
    display: block;
  }
}
md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-active:not([disabled]),
md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-active:not([disabled]),
md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-active:not([disabled]) md-icon,
md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-active:not([disabled]) md-icon,
md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-focused:not([disabled]),
md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-focused:not([disabled]),
md-tabs.md-default-theme.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-focused:not([disabled]) md-icon,
md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item.md-focused:not([disabled]) md-icon {
  color: #666;
}
md-tabs.md-accent.md-active {
  color: #428bca;
}
md-tabs.md-default-theme.md-accent > md-tabs-wrapper,
md-tabs.md-accent > md-tabs-wrapper {
  background-color: #ffffff;
}
md-tab-item.md-active {
  color: #428bca;
}
md-tabs.md-accent md-tabs-wrapper md-tabs-canvas md-pagination-wrapper md-ink-bar {
  color: #428bca;
  /* this changes the colour of the ripple */
  background-color: #428bca;
  /* this changes the colour of the ink bar */
}
.theme-site md-list-item.md-2-line .md-list-item-text p,
.theme-site md-list-item.md-2-line > .md-no-style .md-list-item-text p,
.theme-site md-list-item.md-3-line .md-list-item-text p,
.theme-site md-list-item.md-3-line > .md-no-style .md-list-item-text p {
  font-weight: inherit;
}
.theme-site md-list-item {
  max-width: 100%;
  overflow: hidden;
  color: #212121;
}
.theme-site md-list-item.site-md-1-line {
  min-height: 25px;
}
.theme-site md-list-item.site-md-1-line:before {
  min-height: 25px;
}
.theme-site md-list-item .md-list-item-inner {
  color: #212121;
}
.theme-site md-list {
  background-color: transparent;
}
.theme-site .md-subheader {
  background: #FFFFFF;
}
.theme-site .md-dense .md-list-item-text h3 {
  font-size: 1rem;
}
/* Progress Bar */
.material-progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0.5rem 0 1rem 0;
  overflow: hidden;
}
.material-progress .determinate {
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  background-color: #428bca;
  transition: width 0.3s linear;
}
.material-progress .indeterminate {
  background-color: #428bca;
}
.material-progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.material-progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}
@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.chosen-container .chosen-results {
  padding: 0px;
  margin: 0px;
}
.chosen-container-single .chosen-drop,
.chosen-container .chosen-drop {
  border-radius: 0 0 4px 4px;
  overflow: hidden;
}
.chosen-container .chosen-results li,
.chosen-container .chosen-results li.active-result {
  -moz-user-select: none;
  background-color: transparent;
  border: medium none;
  color: #212121;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: 48px;
  letter-spacing: 0;
  line-height: 48px;
  margin: 0;
  opacity: 1;
  outline-color: #bdbdbd;
  overflow: hidden;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  white-space: nowrap;
  text-align: left;
}
.chosen-container .chosen-results li.highlighted,
.chosen-container .chosen-results li.active-result.highlighted {
  background-color: transparent;
  background-image: none;
  color: #212121;
}
.chosen-container .chosen-results li:hover,
.chosen-container .chosen-results li.active-result:hover {
  font-weight: initial;
  background-color: #d4d4d4;
}
.chosen-container .chosen-results li.result-selected,
.chosen-container .chosen-results li.active-result.result-selected {
  font-weight: 700;
}
.chosen-container.chosen-container-single .chosen-single div {
  align-self: flex-start;
  color: #212121;
  display: table;
  flex-flow: column nowrap;
  height: 100%;
  position: absolute;
  right: 0px;
  text-align: center;
  vertical-align: top;
  width: 26px;
}
.chosen-container-single .chosen-single span {
  text-align: left;
}
.chosen-container-single .chosen-single abbr {
  width: 18px;
  top: 11px;
  /* left: 0; */
}
b,
strong {
  font-weight: 500 !important;
}
.primary {
  color: #428bca;
}
.accent {
  color: #a54243;
}
a:active {
  outline: none;
}
.error {
  color: #a54243;
}
html {
  -webkit-text-size-adjust: 100%;
}
html {
  font-family: "Raleway";
  font-weight: 300;
  color: #212121;
  font-size: 14px;
}
html * {
  font-family: "Raleway";
}
space {
  margin-left: 5px;
}
main {
  min-height: calc(100vh - 163px);
  position: relative;
  padding: 16px 0;
}
select {
  -webkit-appearance: none;
  color: #212121;
}
input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  font-size: 1rem;
}
* {
  font-weight: 300;
}
html.md-default-theme,
html,
body.md-default-theme,
body {
  background-color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #212121;
  font-weight: 300;
}
h1 {
  font-size: 56px;
  font-weight: 100;
}
h2 {
  font-size: 45px;
}
h3 {
  font-size: 34px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}
a {
  text-decoration: none !important;
  cursor: pointer;
  cursor: hand;
  color: #428bca;
}
.site-sibling-margin + .site-sibling-margin {
  margin-top: 10px;
}
.help-block {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #212121;
}
.transparent {
  opacity: 0;
}
.result-button {
  display: inline-block;
  margin-right: 10px;
}
.result-button:last-child {
  margin-right: 0px;
}
.chosen-container,
.chosen-container * {
  z-index: 999 !important;
}
input {
  border-bottom: 1px solid #212121;
}
.hero {
  min-height: calc(100vh - 163px);
  position: relative;
}
.hero.top {
  justify-content: flex-start;
}
.hero .hero-center {
  margin: 0 auto;
}
.hero .hero-top {
  align-self: center;
  margin-top: 0px;
}
.hero-vertical {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-flow: column nowrap;
  width: 100%;
}
.hero-horizontal {
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  width: 100%;
}
.inline-block {
  display: inline-block;
}
.header-buttons {
  float: right;
}
/**
 * Typography
 */
/*
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'), url(fonts/Roboto-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'), url(fonts/Roboto-Regular.woff) format('woff');
}*/
.container-fluid {
  margin: 0 auto;
  max-width: 1800px;
  padding: 0 20px;
}
.page-header {
  margin-top: 60px;
}
.page-header:first-child {
  margin-top: 20px;
}
.btn-primary {
  background-color: #428bca;
  border-color: #428bca;
}
.btn-default {
  border-color: #212121;
  color: #212121;
}
.btn-inverse {
  border: 1px solid #428bca !important;
  border-radius: 4px !important;
  color: #fff !important;
  background-color: #428bca !important;
}
.container .jumbotron {
  padding-top: 52px;
}
.container .jumbotron > .header {
  border-bottom: 3px solid #428bca !important;
  margin-bottom: 15px;
  margin-top: 30px;
  padding-bottom: 30px;
}
/*h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif; /*Bitter, sans-serif is a nice font*/
/*}*/
/**App-wide animations
*
**/
/*
.animate-slide{
  -webkit-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s;
  -moz-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s;
  -o-transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s;
  transition:all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1.5s;
}*/
/*
.animate-slide {
    -moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;
}
*/
/*
.animate-slide.ng-enter
{
  transition: height 1s;
  height: 0px;
  overflow: hidden;
}
.animate-slide.ng-enter.ng-enter-active{
  height:300px;
}
*/
/* 200ms will be applied between each sucessive enter operation */
/*
.animate-slide.second{
  -webkit-transition-delay:1s;
  transition-delay:1s;
}
  

.animate-show {
  opacity:1
}

.animate-show.ng-hide-add.ng-hide-add-active,
.animate-show.ng-hide-remove.ng-hide-remove-active, {
  -webkit-transition:all linear 0.5s;
  transition:all linear 0.5s;
   -webkit-transition-delay:0.2s;
  transition-delay:0.2s;
}

.animate-show.ng-hide {
  line-height:0;
  opacity:0;
  padding:0 0;
}
*/
.animate-slide {
  opacity: 1;
}
.animate-slide.ng-enter {
  opacity: 0;
}
.animate-slide.ng-enter.ng-enter-active {
  opacity: 1;
  transition: 1s linear all;
  -webkit-transition: 1s linear all;
  -moz-transition: 1s linear all;
  -o-transition: 1s linear all;
}
.animate-if.ng-enter,
.animate-if.ng-leave {
  -webkit-transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  transition: all cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}
.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
}
.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
  opacity: 1;
}
.slide-in-fade-out {
  -webkit-transition: color 0.15s, background-color 0.15s;
  -moz-transition: color 0.15s, background-color 0.15s;
  -ms-transition: color 0.15s, background-color 0.15s;
  transition: color 0.15s, background-color 0.15s;
  /* &.ng-leave {
    -webkit-animation: fadeOut .2s;
    -moz-animation: fadeOut .2s;
    -ms-animation: fadeOut .2s;
    animation: fadeOut .2s;
  }*/
}
.slide-in-fade-out.ng-enter {
  display: none;
  -webkit-animation: fadeInLeft 0.3s;
  -moz-animation: fadeInLeft 0.3s;
  -ms-animation: fadeInLeft 0.3s;
  animation: fadeInLeft 0.3s;
}
.slide-in-fade-out.right.ng-enter {
  display: none;
  -webkit-animation: fadeInRight 0.3s;
  -moz-animation: fadeInRight 0.3s;
  -ms-animation: fadeInRight 0.3s;
  animation: fadeInRight 0.3s;
}
.slide-in-fade-out.bottom.ng-enter {
  opacity: none;
  -webkit-animation: fadeInUp 0.3s;
  -moz-animation: fadeInUp 0.3s;
  -ms-animation: fadeInUp 0.3s;
  animation: fadeInUp 0.3s;
}
.slide-in-fade-out.ng-enter-active {
  display: block !important;
}
.slide-in-fade-out.loading.ng-leave,
.slide-in-fade-out.ng-leave {
  display: none;
}
.fade-in-fade-out {
  opacity: 1;
}
.fade-in-fade-out.ng-enter {
  opacity: 0;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
}
.fade-in-fade-out.ng-enter.delay {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  transition-delay: 0.2s;
}
.fade-in-fade-out.up.ng-enter {
  opacity: 1;
  -webkit-animation: fadeInUp 0.3s;
  -moz-animation: fadeInUp 0.3s;
  -ms-animation: fadeInUp 0.3s;
  animation: fadeInUp 0.3s;
}
.fade-in-fade-out.ng-leave {
  display: none;
  /*opacity:0;
    -webkit-animation: fadeOut .2s;
    -moz-animation: fadeOut .2s;
    -ms-animation: fadeOut .2s;
    animation: fadeOut .2s; 
    &.up
    {
      -webkit-animation: fadeOutUp .3s;
      -moz-animation: fadeOutUp .3s;
      -ms-animation: fadeOutUp .3s;
      animation: fadeOutUp .3s;
    }*/
}
.loading-header + .mdl-spinner {
  margin-left: 14px;
}
.stagger-fade h1,
.stagger-fade h2,
.stagger-fade h3,
.stagger-fade h4,
.stagger-fade h5,
.stagger-fade h6 {
  display: inline-block;
  /*needed for chrome to animate?*/
  position: relative;
}
.stagger-fade.ng-enter {
  /* standard transition code */
  transition: 0.5s linear all;
  -webkit-transition: 0.5s linear all;
  opacity: 0;
}
.stagger-fade.ng-enter-stagger,
.fade-in-fade-out.ng-enter-stagger {
  /* this will have a 100ms delay between each successive leave animation */
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
  /* in case the stagger doesn't work then the duration value
   must be set to 0 to avoid an accidental CSS inheritance */
  transition-duration: 0s;
  -webkit-transition-duration: 0s;
}
.stagger-fade.ng-enter.ng-enter-active {
  /* standard transition styles */
  opacity: 1;
}
.text-zoom {
  -webkit-transition: color 0.15s, background-color 0.15s;
  -moz-transition: color 0.15s, background-color 0.15s;
  -ms-transition: color 0.15s, background-color 0.15s;
  transition: color 0.15s, background-color 0.15s;
  -webkit-animation: fadeIn 0.3s;
  -moz-animation: fadeIn 0.3s;
  -ms-animation: fadeIn 0.3s;
  animation: fadeIn 0.3s;
}
/**
 * Now that all app-wide styles have been applied, we can load the styles for
 * all the submodules and components we are using. 
 *
 * TODO: In a later version of this boilerplate, I'd like to automate this.
 */
.header h1 {
  margin-top: 50px;
  padding-bottom: 30px;
  width: 100%;
}
.header h2 {
  margin: 0 15px;
}
.fade.in {
  background-color: rgba(0, 0, 0, 0.5);
}
.center-absolute {
  position: absolute;
  right: 50%;
  top: 50%;
}
.center-absolute > *:first-child {
  right: -50%;
  top: -50%;
}
.login-status {
  position: absolute;
  right: 20px;
  z-index: 9999;
}
.login-status.center {
  right: 50%;
}
.login-status.center .status-inner {
  right: -50%;
}
.login-status .status-inner {
  position: relative;
}
.login-status .status-inner.mdl-card {
  min-width: auto;
}
.login-status .status-inner .mdl-spinner {
  align-self: center;
  margin-left: 20px;
}
.login-status.ng-enter {
  animation: swift-out 0.12s cubic-bezier(0.55, 0, 0.1, 1);
  animation-fill-mode: forwards;
}
[ng-cloak] {
  display: none !important;
}
@keyframes swift-out {
  0% {
    top: 0px;
  }
  100% {
    top: 60px;
  }
}
.loading-ellipsis {
  font-size: 30px;
}
.loading-ellipsis:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
@media screen and (max-width: 393px) {
  .chosen-container:nth-of-type(even) {
    margin-top: 10px;
  }
}
.theme-site sq-loading,
.theme-site .sq-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 999;
}
.theme-site sq-loading .search-loading,
.theme-site .sq-loading .search-loading {
  position: relative;
  left: -50%;
}
/**
 * Footer
 */
.footer {
  border-top: 1px solid #428bca;
  margin-top: 30px;
}
.footer .footer-inner {
  padding: 10px 0;
}
.footer .footer-inner p {
  margin: 0;
}
.footer .social {
  float: right;
  margin: 0;
  list-style: none;
}
.footer .social li {
  float: left;
  margin-left: 20px;
}
.footer .social li a,
.footer .social li a:visited {
  color: #d4d4d4;
  font-size: 26px;
  text-decoration: none;
  transition: 250ms ease-in-out;
}
.footer .social li a:hover,
.footer .social li a:visited:hover {
  color: #212121;
}
.theme-site .help-page {
  font-size: 15px;
}
.theme-site .help-page img {
  width: 500px;
}
.theme-site .help-page .md-button {
  margin: 0;
  line-height: 1;
}
.theme-site .help-page code,
.theme-site .help-page pre,
.theme-site .help-page .pre {
  font-weight: 500;
  /* background-color: #efefef; */
  letter-spacing: 0.8px;
  /* color: #555; */
  border-bottom: 1px solid #212121;
}
.theme-site .help-page ol,
.theme-site .help-page ul {
  line-height: 30px;
  padding: 0px 10px 0px 15px;
}
.theme-site .help-page ol {
  margin-left: 18px;
}
.theme-site .help-page ul {
  list-style: none;
}
.theme-site .help-page md-list-item > md-list {
  padding-top: 12px;
}
.theme-site .help-page md-icon.small.list-center {
  line-height: 1.6;
}
.theme-site .help-page #help .help-gif {
  width: 100%!important;
  max-width: 500px;
  height: auto!important;
}
.home {
  text-align: center;
  height: 100%;
  width: 100%;
}
.home .btn {
  font-size: 21px;
  padding: 14px 24px;
}
.home .lead {
  font-size: 24px;
  line-height: 1.25;
}
.home .list-inline > li {
  max-width: 120px;
}
.marketing {
  text-align: center;
}
.marketing .row {
  /* margin-top: 0px; */
  background-color: #eee;
  margin-bottom: 0;
  border-radius: 0;
  text-align: left;
}
.marketing .row.even {
  text-align: right;
  background-color: #fff;
}
.marketing h4 [class*=" icon-"],
.marketing h4 [class^="icon-"] {
  margin-right: 5px;
  vertical-align: -10%;
  font-size: 28px;
  line-height: 1;
}
md-list-item.full-width {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.loading-header {
  position: relative;
}
.visualization {
  align-items: flex-start;
  display: flex;
  flex: 1 1 0;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
}
.vertical-text {
  white-space: pre-wrap;
  text-align: center!important;
}
.rotate-180 {
  transform: rotate(180deg);
}
.editable {
  position: relative;
  outline: none;
  cursor: pointer;
  cursor: hand;
}
.editable:hover:before {
  content: "Edit";
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  text-align: left;
  font-weight: 500;
}
.status {
  margin-top: 11px;
}
h1 .material-progress {
  margin-bottom: 0;
  width: auto;
  height: 2px;
}
.hero.top {
  padding-top: 0px;
}
.progress-wrapper {
  max-height: 300px!important;
  overflow-y: scroll;
}
.progress-wrapper.expand-height {
  max-height: 500px!important;
}
.progress-wrapper md-progress-circular {
  position: relative;
  top: 20px;
}
.progress-wrapper .md-fab.expand i {
  font-size: 25px;
  line-height: 37px;
}
.progress-wrapper.log:hover {
  cursor: pointer;
  cursor: blank;
}
.progress-wrapper .md-button.expand {
  position: absolute;
  top: 0px;
  left: 0px;
}
.site-layout-single .progress-wrapper {
  display: none;
}
.progress-text {
  left: 50%;
  margin-bottom: 0 !important;
  margin-top: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.progress-text + .progress-text {
  top: 60%;
}
.progress-text.notification-count {
  cursor: pointer;
  font-size: 14px;
  height: 32px;
  left: 66% !important;
  line-height: 1;
  min-height: auto;
  min-width: auto;
  position: absolute;
  top: 30%;
  width: 32px;
  z-index: 1;
}
.progress-text.notification-count.left {
  left: 47%;
}
.status button {
  position: absolute;
  right: 5px;
  top: 5px;
}
.status {
  border: 1px solid;
  display: block;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  transition: 0.5s all;
  border-radius: 3px;
  border-color: #d4d4d4;
}
.status .status button {
  background-color: #fff;
  border: 1px solid;
  border-radius: 3px;
  box-shadow: none;
  position: absolute;
  right: 5px;
  top: 5px;
}
.status button {
  background-color: #FFFFFF;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  right: 5px;
  top: 5px;
  color: #d4d4d4;
}
.status button:hover {
  color: #428bca;
  border-color: #428bca;
}
.status .message-container {
  height: 191px;
  margin-top: 10px;
}
.status .message-container .repeat-item {
  padding: 10px;
}
.progress-circle {
  bottom: 0;
  height: 282px!important;
  padding: 0 !important;
  position: absolute!important;
  width: 238px!important;
}
.progress-circle .round-progress > circle:first-child {
  stroke: #d4d4d4 !important;
}
.round-progress-wrapper {
  display: flex;
  height: 100%;
  padding-bottom: 0 !important;
}
.round-progress {
  max-height: 310px !important;
  width: 100% !important;
  position: relative!important;
}
#login-form md-content {
  position: static;
}
md-progress-linear {
  height: 0;
}
md-progress-linear .md-container {
  display: block;
  height: 1px;
  overflow: hidden;
  position: relative;
  top: -22px;
  transform: translate(0px, 0px) scale(1, 1);
  width: 100%;
}
md-progress-linear.md-default-theme .md-container,
md-progress-linear .md-container,
.mdl-progress__indeterminate {
  background-color: #212121;
}
md-progress-linear.md-default-theme .md-bar,
md-progress-linear .md-bar {
  background-color: #428bca;
}
md-card-content.progress-wrapper .md-subheader {
  background-color: #FFFFFF;
}
md-card-content.progress-wrapper .md-subheader.primary {
  background-color: #428bca;
  color: #FFFFFF;
}
md-card-content.progress-wrapper .md-subheader.accent {
  background-color: #a54243;
  color: #FFFFFF;
}
.virtualRepeatdemoScrollTo .wrapper {
  width: 400px;
}
.virtualRepeatdemoScrollTo #vertical-container {
  height: 292px;
  width: 400px;
}
.virtualRepeatdemoScrollTo #vertical-container .repeated-item {
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  height: 40px;
  padding-top: 10px;
}
.virtualRepeatdemoScrollTo #vertical-container .repeated-item.header {
  background-color: #106CC8;
  color: white;
  text-align: center;
  font-weight: bold;
}
.virtualRepeatdemoScrollTo #vertical-container md-content {
  margin: 16px;
}
.virtualRepeatdemoScrollTo md-virtual-repeat-container {
  border: solid 1px grey;
}
.virtualRepeatdemoScrollTo .md-virtual-repeat-container .md-virtual-repeat-offsetter div:not(.header) {
  padding-left: 16px;
}
.annotation-title {
  margin-top: 0px;
}
md-list-item {
  text-align: left;
}
@media (max-width: 600px) {
  .site-layout-single .progress-wrapper:last-of-type {
    width: 320px;
  }
}
#visualization {
  clear: both;
  float: left;
  height: auto;
  width: 70%;
  min-width: 320px;
  min-height: 60vh;
  position: relative;
}
foam-details,
.foam-details {
  max-height: inherit;
}
#visualization-default {
  width: 100%;
  align-self: center;
}
#visualization-details-overlay .close-button {
  position: absolute;
  right: 15px;
  top: 15px;
}
foam-details .close-foam-details,
.foam-details .close-foam-details {
  position: absolute !important;
  right: 10px;
  top: 10px;
  z-index: 999;
}
#visualization-search {
  display: block;
  float: none;
  margin: 0 auto;
}
#visualization-search .result-button {
  display: inline-block;
  margin-right: 10px;
}
#visualization-search .chosen-choices {
  background-image: none;
  border: 1px solid #212121;
  height: 34px !important;
  border-radius: 3px;
  box-shadow: none;
  display: block;
  padding: 4px 12px;
  width: 188px;
  overflow-y: scroll;
}
#visualization-search .chosen-choices input {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  font-family: inherit;
  color: #212121;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
}
h2.chart-title {
  display: block;
  margin: 0 0px 7px 0px;
}
.foam-tree-container {
  float: left;
  width: 100%;
  flex: 1;
  display: flex;
  min-height: 500px;
}
.foam-tree-container #visualization-details {
  position: relative;
  height: 60vh;
}
.foam-tree-container #visualization-details-overlay {
  position: absolute;
  z-index: 9999;
  height: auto;
  max-height: 100%;
  right: 0px;
  top: 0;
  margin: 0;
  width: 100%;
}
.foam-tree-container md-card-content {
  overflow: scroll;
  max-height: 90%;
}
.foam-tree-container md-card-header {
  min-height: 40px;
}
sq-bar-chart {
  clear: both;
  display: block;
}
sq-bar-chart svg {
  height: 480px;
}
/*Hide x axis, to "fix" nvd3 bar width workaround resulting being NaN x values*/
.nv-x .nv-axis .tick text {
  display: none;
}
.md-list-item-text h5 {
  font-weight: 700;
}
.md-list-item-text .indent {
  padding-left: 16px;
}
@media screen and (max-width: 767px) {
  #visualization {
    width: 96.5%;
  }
}
.theme-site sq-search-hints {
  position: absolute;
  top: -31px;
  left: -14px;
  z-index: 1;
  width: 10px;
  height: 10px;
}
.theme-site .annotation-result-buttons {
  /*float: left;
    margin-bottom: -30px;
    margin-left: 20px;
    opacity: 1;
    padding: 0 !important;
    position: relative;
    right: 0;
    width: auto;*/
  /*&.ng-enter
    {
      transition: .7s linear all;
      -webkit-transition: .7s linear all;
      -moz-transition: .7s linear all;
      -o-transition: .7s linear all;
      left:-100px;
      opacity:0;
      position:relative
    }*/
  /*Todo: make button border radius a variable*/
}
.theme-site .annotation-result-buttons .btn,
.theme-site .annotation-result-buttons form {
  display: inline-block;
}
.theme-site .annotation-result-buttons .btn {
  /*border-radius: 50%;
      font-size: 16px;
      height: 50px;
      line-height: 0;
      margin-bottom: 0;
      padding: 24px 0 0;
      width: 50px !important;*/
}
.theme-site .annotation-result-buttons .btn.fa-cloud-download {
  padding-top: 0;
}
.theme-site .annotation-result-buttons .fa > span {
  margin-left: 5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*to do, define font family site wide*/
}
.theme-site .annotation-result-buttons .btn-sm,
.theme-site .annotation-result-buttons .btn-group-sm > .btn {
  font-size: 14px;
}
.theme-site .annotation-result-buttons input {
  margin-top: 0;
}
.theme-site .annotation-result-buttons.ng-enter.ng-enter-active {
  left: 0px;
  opacity: 1;
}
.theme-site .annotation-result-buttons form .btn {
  margin: 0;
}
.theme-site .annotation-result-buttons .btn-group .btn:first-of-type:not(':last-child') {
  border-top-left-radius: 3px!important;
  border-bottom-left-radius: 3px!important;
}
.theme-site #result_chooser_chosen {
  margin-bottom: 6px;
}
.theme-site .top #result-choose-container {
  transition: all 300ms ease 0s;
  margin-bottom: 20px;
}
.theme-site .top #result-choose-container > h2 {
  margin-top: 0;
  margin-bottom: 0;
}
.theme-site .top #result-choose-container #result_chooser_chosen {
  margin-bottom: 0px;
}
.theme-site .chosen-container-multi .chosen-choices li.search-choice {
  background-clip: padding-box;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #428bca;
  border-radius: 3px;
  box-shadow: none;
  color: #212121;
  cursor: default;
  line-height: 13px;
  margin: 0;
  padding: 5px 20px 5px 5px;
  position: relative;
}
.theme-site .chosen-container-multi .chosen-choices li.search-field {
  padding: 0px;
  white-space: nowrap;
  height: 100%;
}
.theme-site .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
  /*  background: rgba(0, 0, 0, 0) url("chosen-sprite.png") no-repeat scroll -42px 1px;
    display: block;
    font-size: 1px;
    height: 12px;*/
  position: absolute;
  right: 4px;
  top: 6px;
  width: 12px;
}
.theme-site #result-chooser .ui-select-match {
  width: 207.5px;
}
.theme-site #result-chooser .ui-select-search {
  width: 207.5px;
}
.theme-site .loading-indicator-narrow {
  min-width: 225px;
}
.theme-site .loading-indicator h1 {
  margin-right: 20px;
}
.theme-site .mode-search {
  overflow: hidden;
  background-color: red;
}
.theme-site .mode-search .form,
.theme-site .mode-search .icon-close {
  opacity: 1;
  transform: none;
  pointer-events: all;
}
.theme-site .mode-search .control .icon-search {
  opacity: 0;
}
.theme-site .mode-search .control .btn {
  transform: scale(70);
}
.theme-site #result-toolbar {
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-bottom: 16px;
}
.theme-site #result-toolbar .md-toolbar-tools {
  max-height: none;
  height: 78px;
}
.theme-site #result-toolbar .md-toolbar-tools label {
  font-size: 1rem;
}
.theme-site md-menu-content .md-button {
  text-align: left;
}
.theme-site .md-icon-button.wings {
  margin-left: 0px!important;
  margin-right: 0px!important;
}
.theme-site .no-outline {
  outline: none;
}
.theme-site md-card.overview-search-card .md-actions,
.theme-site md-card.overview-search-card md-card-actions {
  margin: 8px;
  text-align: right;
}
.theme-site .search-loading {
  left: 50%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.theme-site .has-tooltip {
  cursor: pointer;
  cursor: hand;
}
.theme-site #search-container {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 60px;
}
.theme-site #search-container md-card-content > * {
  text-align: left;
}
.theme-site #search-container md-card-title > * {
  display: block;
  text-align: center;
  margin: 0 auto;
}
.theme-site #search-container.searching::after {
  content: '';
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.theme-site .initial-text-align {
  text-align: initial;
}
.theme-site md-menu-bar button {
  background-color: transparent;
  border: 0 none;
  font-size: 14px;
  height: 40px;
  margin: 0;
  padding: 0 10px;
}
.theme-site md-menu md-button i {
  top: -2px;
  position: relative;
}
.theme-site md-menu-bar .md-menu {
  display: inline-block;
  padding: 0;
  position: relative;
}
.theme-site .md-open-menu-container {
  background-color: white;
}
.theme-site #full-card-container button.expand i {
  font-size: 16px;
}
.theme-site md-card.overview-search-card,
.theme-site #search-sort-menu {
  /* Fixes crazy height overflow */
  min-height: min-content !important;
}
.theme-site .filter-list md-list {
  max-height: 320px;
  overflow: scroll;
  border-bottom: 1px solid #d3d3d3;
  border-top: 1px solid #d3d3d3;
  word-break: break-all;
}
.theme-site .filter-list md-list md-chip {
  word-break: keep-all;
  margin-left: 10px;
}
.theme-site .filter-list md-list .md-list-item-inner {
  margin-bottom: 10px;
}
.theme-site .filter-list:last-child md-list {
  border-bottom: 0px;
}
.theme-site md-checkbox .md-icon::after {
  box-sizing: border-box;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 4.66667px;
  top: 0.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
  color: #efefef;
}
.theme-site md-checkbox.md-checked .md-icon::after {
  color: #fff;
}
.theme-site md-checkbox.negative .md-icon::after {
  box-sizing: border-box;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  left: 4.66667px;
  top: 0.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-bottom: 0px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
  color: #efefef;
}
.theme-site md-checkbox.negative.md-checked .md-icon::after {
  box-sizing: border-box;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  position: absolute;
  left: 4.66667px;
  top: 0.22222px;
  display: table;
  width: 6.66667px;
  height: 13.33333px;
  border-width: 2px;
  border-bottom: 0px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  content: "";
}
.theme-site md-checkbox[disabled] {
  color: #d4d4d4 !important;
}
.theme-site .md-chips {
  outline: none;
}
.theme-site .md-chips md-chip {
  background: #d4d4d4;
  outline: none;
}
.theme-site md-chip-template {
  outline: none;
}
.theme-site md-chip-template:hover {
  color: #428bca;
}
.theme-site .show-over {
  display: none;
}
.theme-site md-chip-template.highlight .show-over {
  display: block;
}
.theme-site md-chip-template.highlight .hide-over {
  display: none;
}
.theme-site #search-content {
  position: relative;
  min-height: 80px;
}
.theme-site md-list.short {
  max-height: 400px;
  overflow: scroll;
}
.theme-site md-list.tall {
  max-height: 800px;
  overflow: scroll;
}
@media (max-width: 600px) {
  .theme-site #search-container {
    display: block;
  }
}
.theme-site md-input-container.slide-out-hidden-input {
  margin-bottom: 0px;
  margin-top: 8px;
  margin-left: 8px;
  width: 170px;
}
.theme-site md-input-container.slide-out-hidden-input input {
  font-size: 14px;
  height: 26px;
}
.theme-site .sq-md-no-spacer .md-errors-spacer {
  display: none;
}
/**
 * This is the main sequencing form stylesheet. It should include or import all
 * stylesheets used throughout the application relating to sequencing forms
 */
.header-buttons .btn:first-child {
  margin-left: 0px;
}
hr {
  color: #FFFFFF;
}
.queue.btn {
  width: 82px;
}
.email-choice .form-control {
  width: 100%;
}
.email-choice .form-control.ng-invalid {
  color: #a54243;
  border-color: #a54243;
}
.email-choice .form-control.ng-invalid:focus {
  border-color: #a54243;
  box-shadow: none;
  outline: 0 none;
}
.email-choice .email-footer {
  margin: 0 auto 20px;
  position: relative;
}
.email-choice .email-footer .error {
  left: 0;
  position: absolute;
  font-size: 12px;
  color: #a54243;
}
.form-content {
  text-align: center;
}
.uploader-parent {
  margin-top: 10px;
}
.submission-form-card {
  z-index: auto;
}
.submission-form-card md-tabs md-tabs-content-wrapper {
  width: 500px;
}
.submission-form-card md-tabs md-tabs-content-wrapper .hint {
  bottom: 0;
  clear: left;
  color: #333;
  font-size: 0.875rem;
  left: 0;
  padding-left: 16px;
  position: absolute;
  right: auto;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2) 0s;
}
.submission-form-card md-list-item {
  margin-bottom: 10px;
}
.form-control {
  box-shadow: none;
  color: #212121;
  border-color: #212121;
}
#inner-form {
  border: 1px solid #212121;
  border-radius: 3px;
  padding: 20px;
  text-align: center;
}
.login > .login {
  display: table-cell;
  vertical-align: middle;
  width: 300px;
}
.container.login {
  display: table;
  height: 675px;
  width: 100%;
}
.narrow-form {
  width: 285px;
}
.narrow-form.no-border {
  border: 0px;
  padding: 0px;
}
.two-select {
  width: 398px;
}
.form {
  height: 500px;
  display: table;
  width: 100%;
  /*  min-height:410px;
  position:relative;
  margin-top:0px;*/
}
.form.fired-form {
  min-height: 0px;
  transition: all 0.15s;
}
.form.fired-form.ng-add {
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.form.fired-form.ng-remove-active {
  -webkit-animation: fadeOut 1s;
  -moz-animation: fadeOut 1s;
  -ms-animation: fadeOut 1s;
  animation: fadeOut 1s;
}
.form-header {
  margin-top: 29px;
}
.btn.reset {
  position: absolute;
  right: 60px;
  top: 10px;
}
.select2-container {
  width: 100px;
}
.modal-footer .error {
  float: left;
  color: #a54243;
}
#form-submission-buttons {
  margin-top: 25px;
}
embed {
  display: block;
  height: auto;
  width: 100%;
}
.annotation-complete img {
  height: 40px;
  margin-left: -5px;
  margin-right: -8px;
  margin-top: -9px;
}
.annotation-progress {
  font-style: italic;
}
.chosen-container {
  min-width: 176px;
}
.chosen-container:nth-of-type(2) {
  margin-left: 15px;
}
.chosen-container .chosen-drop {
  margin-top: -2px;
  width: 100%;
  border-width: 0px 1px 1px;
  border-style: none solid solid;
  border-color: #212121;
  border-image: none;
  background: #FFFFFF none repeat scroll 0% 0%;
  box-shadow: none;
}
.chosen-container.chosen-container-single .chosen-single {
  background: #FFFFFF;
  border: 1px solid #212121;
  border-radius: 2px;
  box-shadow: none;
  color: #212121;
  height: 23px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  font-size: 14px;
  height: 35px;
  line-height: 1.5;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s;
  width: 100%;
}
.chosen-container.chosen-container-single .chosen-single div {
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 6px;
  width: 18px;
}
.chosen-md-5 .chosen-container {
  width: 50%;
}
.chosen-md-6 .chosen-container {
  width: 60%;
}
.chosen-md-7 .chosen-container {
  width: 70%;
}
.chosen-md-8 .chosen-container {
  width: 80%;
}
.form-group {
  margin-bottoosem: 5px;
  padding-left: 0;
  display: block;
  margin: 20px auto;
}
.form-group:last-child {
  margin-bottom: 0px;
}
.form-group + .form-group {
  margin-top: 10px;
}
.narrow-form .form-group {
  width: 100%;
}
.form-group.col-xs-8 .chosen-container {
  max-width: 188px;
}
input[multiple] {
  width: 6.2em;
}
.sel-file {
  padding: 1px 5px;
  font-size: smaller;
  color: grey;
}
.response {
  padding-top: 30px;
}
.response > ul {
  list-style-type: decimal;
}
.response li,
.response .reqh {
  color: blue;
  font-size: smaller;
  padding-bottom: 5px;
}
.progress {
  border: 1px none;
  box-shadow: none !important;
  display: inline-block;
  height: auto;
  margin-bottom: -7px;
  padding: 0;
  width: 100px;
}
.progress div {
  background: none repeat scroll 0 0 #428bca;
  color: #FFFFFF;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  width: 0;
}
.drop-box {
  background: #F8F8F8;
  border: 5px dashed #DDD;
  width: 200px;
  height: 65px;
  text-align: center;
  padding-top: 35px;
}
.upload-info {
  margin-top: 10px;
}
.file-info.ng-binding {
  position: absolute;
  top: -7px;
  left: 0px;
  font-size: 11px;
}
.form-inline {
  position: relative;
}
.drop-box.dragover {
  border: 5px dashed blue;
}
/* for IE*/
.js-fileapi-wrapper {
  display: inline-block;
  vertical-align: middle;
}
button {
  padding: 1px 5px;
  font-size: smaller;
  margin: 0 3px;
}
.ng-v {
  float: right;
}
.sel-file img {
  float: left;
  width: 18px;
  height: 18px;
  padding-right: 10px;
}
.sub {
  font-size: smaller;
  color: #777;
  padding-top: 5px;
  padding-left: 10px;
}
.err {
  font-size: 12px;
  color: #C53F00;
  margin: 15px;
  padding: 15px;
  background-color: #F0F0F0;
  border: 1px solid black;
}
.queue-progress button:first-of-type {
  margin-left: 0;
}
.upload-file-name {
  display: block;
  max-width: 192px;
  overflow: hidden;
  word-wrap: break-word;
}
.option-parent:not(:last-of-type) {
  margin-bottom: 10px;
}
.option-parent .option-child:not(:last-child) {
  margin-bottom: 10px;
}
.option-stepchild {
  margin-left: 15px;
}
.option-stepchild h5 {
  display: inline-block;
  width: 100px;
}
.option-stepchild input {
  border: 1px solid #eee;
  border-radius: 5px;
  width: 100px;
  padding: 5px;
  margin-right: 15px;
}
.custom-autocomplete {
  margin-top: -18px;
  margin-bottom: -18px;
}
md-list-item.custom-list-item {
  padding-left: 1px;
  margin-bottom: -26px;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #212121;
}
thead {
  background-color: #428bca;
  color: #FFFFFF;
}
thead th {
  padding: 8px 15px;
  border-bottom: 2px solid #212121;
  font-weight: bold;
  text-align: left;
}
tbody tr.striped-row {
  background-color: rgba(0, 0, 0, 0.02);
}
tbody tr td {
  padding: 8px 15px;
  border-bottom: 1px solid #212121;
}
@media screen and (max-width: 1200px) {
  /*only apply below n width*/
  .btn.btn-warning.btn-xs {
    display: none;
  }
  .upload-file-name {
    max-width: 113px;
  }
  .annotation-progress {
    display: inline-block;
    margin-left: 0px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }
}
input[type="checkbox"] + h5 {
  display: inline-block;
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .annotation-result-buttons {
    margin: 8px 0px 12px 20px;
  }
  .form {
    height: 333.33333333px;
  }
}
@media screen and (max-width: 767px) {
  #result-choose-container .header-buttons {
    float: none;
    margin: 16px auto 0px;
    text-align: center;
    width: 129px;
    display: block;
  }
  #result-choose-container .inline-block {
    clear: both;
    display: block;
    text-align: center;
  }
  .btn.btn-warning.btn-s {
    display: none;
  }
}
.uploader-controls tr:first-child th {
  line-height: 1;
  padding-top: 0 !important;
}
.uploader-parent {
  margin-top: 10px;
}
.uploader-parent .file-upload {
  margin: 0px 0px 0 0;
  overflow: hidden;
  position: relative;
}
upload label.md-button {
  background-color: #FFFFFF;
  color: #212121;
}
.uploader-controls .upload-item-error {
  cursor: pointer;
  cursor: hand;
}
.uploader-controls .btn {
  margin-top: 10px;
}
.uploader-controls .btn.fa {
  font-size: 20px;
}
.uploader-controls th {
  font-size: 16px;
}
.uploader-controls td {
  padding: 0px;
  border-top: 0px!important;
}
.uploader-controls td > div,
.uploader-controls td > strong {
  padding: 5px;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px;
}
.uploader-controls td > div.progress,
.uploader-controls td > strong.progress {
  padding: 0px!important;
}
.uploader-controls td > div .tooltip,
.uploader-controls td > strong .tooltip {
  padding: 10px;
}
.uploader-controls td .btn {
  margin-top: 0px;
}
input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
  z-index: 999;
}
.uploader-controls {
  text-align: left;
}
.uploader-controls td {
  min-width: 82px!important;
}
.uploader-controls td,
.uploader-controls td *,
.uploader-controls th,
.uploader-controls th * {
  text-align: center;
}
.uploader-controls .progress {
  width: 200px !important;
}
.uploader-controls h3 {
  margin-top: 0px;
  line-height: 17px;
}
.uploader-controls th {
  font-size: 14px;
}
.uploader-controls > .btn {
  margin-top: 0px!important;
}
.uploader-controls *:last-child {
  margin-bottom: 0px;
  margin-right: 0px;
}
.uploader-controls button:last-child {
  margin-left: 0;
}
.uploader-controls th:last-child,
.uploader-controls td:last-child {
  padding-right: 0 !important;
}
.uploader-controls th:first-child,
.uploader-controls td:first-child {
  padding-left: 0 !important;
}
.uploader-controls .table {
  width: auto;
  margin: 0 auto!important;
}
.uploader-controls .table > thead > tr > th,
.uploader-controls .table > tbody > tr > th,
.uploader-controls .table > tfoot > tr > th,
.uploader-controls .table > thead > tr > td,
.uploader-controls .table > tbody > tr > td,
.uploader-controls .table > tfoot > tr > td {
  vertical-align: middle;
  padding: 10px;
}
.upload-exit-top div,
.upload-exit-top strong {
  height: 0px;
  padding: 0px;
  transform: translate(0px, -600px) scaley(0);
}
@keyframes exit_top {
  to {
    top: -500px;
  }
}
.animate-enter div,
.animate-leave div,
.animate-enter strong,
.animate-leave strong {
  transition: 4000ms cubic-bezier(0.95, 0.05, 0.795, 0.035) all;
  transition-delay: 2s;
  position: relative;
  display: block;
}
.animate-enter.animate-enter-active div,
.animate-leave div,
.animate-enter.animate-enter-active strong,
.animate-leave strong {
  opacity: 1;
  top: -500px;
  height: 30px;
  display: none;
}
.animate-leave.animate-leave-active div,
.animate-enter div,
.animate-leave.animate-leave-active strong,
.animate-enter strong {
  opacity: 0;
  top: 0;
  height: 0px;
}
@media screen and (max-width: 528px) {
  .uploader-controls tbody tr th:nth-child(n+4),
  .uploader-controls thead tr th:nth-child(n+4),
  .uploader-controls tbody tr td:nth-child(n+4),
  .uploader-controls thead tr td:nth-child(n+4) {
    display: none;
  }
  td div,
  td strong {
    max-width: 100%;
  }
}
@media screen and (min-width: 375px) and (max-width: 528px) {
  .uploader-controls td div,
  .uploader-controls td strong {
    max-width: 164px;
  }
}
@media screen and (min-width: 319px) and (max-width: 374px) {
  .uploader-controls td div,
  .uploader-controls td strong {
    max-width: 140px;
  }
}
/**
 * Navigation
 */
.nav-progress {
  position: absolute;
  top: 0;
  left: 87%;
  z-index: 999;
}
@keyframes swing {
  15% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    color: #428bca;
  }
  30% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    color: #428bca;
  }
  50% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
    color: #428bca;
  }
  65% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
    color: #428bca;
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
    color: #428bca;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    color: normal;
  }
}
@keyframes swing-shadow {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    box-shadow: 0 0 0 0px #428bca inset;
  }
  5% {
    -webkit-transform: translateX(5px);
    transform: translateX(0px);
    box-shadow: 0 0 0 0.5px #428bca inset;
  }
  10% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    box-shadow: 0 0 0 1px #428bca inset;
  }
  15% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    box-shadow: 0 0 0 1.5px #428bca inset;
  }
  20% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
    box-shadow: 0 0 0 2px #428bca inset;
  }
  30% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
    box-shadow: 0 0 0 2.5px #428bca inset;
  }
  50% {
    -webkit-transform: translateX(3px);
    transform: translateX(3px);
    box-shadow: 0 0 0 3px #428bca inset;
  }
  65% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
    box-shadow: 0 0 0 2.5px #428bca inset;
  }
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
    box-shadow: 0 0 0 2px #428bca inset;
  }
  90% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
    box-shadow: 0 0 0 1px #428bca inset;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    box-shadow: 0 0 0 0px #428bca inset;
  }
}
.nav-link.md-button {
  text-transform: none;
  min-width: 30px;
}
.nav-link.md-button.upper-case {
  text-transform: uppercase;
}
.collapse {
  display: none;
}
.brand.navbar-brand .Aligner {
  margin-top: -3px;
}
.active a {
  color: #428bca;
}
#navbar-container {
  overflow: visible;
}
#navbar-container ul {
  padding-left: 0px;
}
#navbar-container ul,
#navbar-container li {
  list-style-type: none;
}
#navbar-container a,
#navbar-container button {
  font-size: 15px;
}
#navbar-container a:hover {
  text-decoration: none;
}
#navbar-container.navbar-fixed-parent.top {
  margin-bottom: 51px;
}
#navbar-container .navbar {
  background-color: #FFFFFF;
}
#navbar-container .navbar small {
  font-size: 60%;
}
#navbar-container .navbar a {
  cursor: pointer;
  cursor: hand;
}
#navbar-container .navbar a:focus {
  outline: 0;
}
#navbar-container .navbar a:after {
  border-bottom: 1px solid transparent;
  content: "";
  display: block;
  transition: 0.5s all;
  width: 100%;
}
#navbar-container .navbar a.active {
  color: #428bca;
}
#navbar-container .navbar a.active.md-fab,
#navbar-container .navbar a.active.site-button--colored {
  color: #FFFFFF;
}
#navbar-container .navbar a.active:after {
  border-bottom: 1px solid #428bca;
}
#navbar-container .navbar a.new {
  animation: swing-shadow 1s ease;
  animation-iteration-count: 1;
}
#navbar-container .navbar a.new-swing {
  animation: swing 1s ease;
  animation-iteration-count: 1;
}
#navbar-container .navbar a > * {
  font-weight: inherit;
}
#navbar-container .navbar .navbar-brand a {
  border-radius: 50%;
  transition: 0.5s all;
}
#navbar-container .navbar .navbar-brand a.active {
  border-radius: 3px;
}
#navbar-container .navbar .navbar-brand a.active:after {
  border-bottom: 0px;
}
#navbar-container .navbar .navbar-header #navbar-toggle-parent:focus {
  background: none;
}
#navbar-container .navbar .navbar-nav > li,
#navbar-container .navbar .navbar-header > li {
  font-size: 16px;
  position: relative;
}
#navbar-container .navbar .navbar-nav > li.brand,
#navbar-container .navbar .navbar-header > li.brand {
  margin-left: 0px;
}
#navbar-container .navbar .navbar-nav > li.brand a,
#navbar-container .navbar .navbar-header > li.brand a {
  font-size: 18px;
  display: inline-flex;
}
#navbar-container .navbar .navbar-nav > li.brand a .Aligner,
#navbar-container .navbar .navbar-header > li.brand a .Aligner {
  width: 100%;
}
#navbar-container .navbar .navbar-nav {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  /*.active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus
      {
        background-color:@gray;
        color:@white;
        font-weight:700;
      }*/
}
#navbar-container .navbar .navbar-nav .notification-count {
  background-color: #428bca;
  border-radius: 3px;
  color: #FFFFFF;
  font-size: 9px;
  font-weight: 700;
  padding: 3px;
  position: absolute;
  left: 88%;
  top: -5px;
  white-space: nowrap;
  z-index: 999;
  line-height: 1;
}
#navbar-container .navbar .navbar-nav > li {
  float: none;
  vertical-align: middle !important;
  display: table-cell;
}
#navbar-container .navbar .navbar-nav > li a:hover {
  font-weight: inherit;
}
#navbar-container .navbar .navbar-nav.navbar-right {
  margin-right: 0px;
}
#navbar-container .navbar .navbar-nav.navbar-right * + * > a {
  margin-left: 10px;
}
#navbar-container .navbar .navbar-nav.navbar-right > li {
  clear: none;
  display: table-cell;
  float: none;
  vertical-align: middle;
}
#navbar-container .navbar .navbar-nav.navbar-right > li:first-child {
  padding-left: 0px;
}
#navbar-container .navbar .navbar-nav.navbar-right .settings {
  padding-left: 5px;
  padding-right: 5px;
}
#navbar-container .navbar .navbar-nav.navbar-right .spacer {
  height: 50px;
  padding: 0;
}
#navbar-container .navbar .navbar-nav .login a.active:after {
  border-bottom: 0px;
}
@media screen and (max-width: 767px) {
  #navbar-container .center.login-name {
    left: 50%;
    position: absolute;
    top: 15px;
  }
  #navbar-container .center.login-name .name-display {
    position: relative;
    left: -50%;
    display: none;
  }
}
#login-form .form-control.ng-invalid-email,
#login-form .form-control.ng-valid-parse.ng-invalid-minlength {
  color: #a54243;
  border-color: #a54243;
}
#login-form .form-control.ng-invalid-email:focus,
#login-form .form-control.ng-valid-parse.ng-invalid-minlength:focus {
  border-color: #a54243;
  box-shadow: none;
  outline: 0 none;
}
#login-form .form-error {
  margin: 0 auto 20px;
  position: relative;
}
#login-form .form-error .error {
  left: 0;
  position: absolute;
  font-size: 12px;
  color: #a54243;
}
#login-form .reset-link {
  position: absolute;
  bottom: 6px;
  right: 0px;
}
#login-form .tooltip {
  background: none;
}
.login__submit {
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
}
.login__submit.md-button {
  min-width: 36px;
}
.login__submit.processing-add * {
  opacity: 0;
}
.login__submit.processing-add md-progress-circular path {
  stroke: #FFFFFF;
}
.login__submit.processing {
  opacity: 1;
  width: 36px;
  background-color: #36465d;
  color: #FFFFFF;
  border-radius: 50%;
  transform: rotate(360deg);
  padding: 0px;
}
.login__submit.processing * {
  opacity: 1;
}
.login__submit.processing md-progress-circular {
  left: 5.5px;
  top: 6px;
  position: absolute;
}
.login__submit.processing md-progress-circular path {
  stroke: #FFFFFF;
}
.login__submit.success {
  background-color: #36465d !important;
  color: #FFFFFF;
}
.login__submit.success.md-icon-button.md-button {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  padding: 0px;
}
.action-result {
  border-radius: 4px;
  color: #333;
  font-weight: 300;
  padding: 10px;
}
.action-result h1,
.action-result h2,
.action-result h3,
.action-result h4,
.action-result h5,
.action-result h6 {
  margin-bottom: 0px;
  margin-top: 0px;
}
.settings-submit {
  transition: all 0.2s;
  height: 40px;
}
.settings-submit.success.md-icon-button.md-button {
  padding: 0px;
}
.btn-register {
  border-color: #fff;
}
.btn-register.btn-inverse {
  border: 1px solid #428bca !important;
  border-radius: 4px!important;
  color: #fff !important;
  background-color: #428bca !important;
}
/*# sourceMappingURL=/app-public/build/assets/bystro-web-1.0.1.css.map */